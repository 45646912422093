import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

import { LabelService } from '../../../../administration/label-management/label.service';
import { PickListOptions } from './pick-list-options';

@Component({
  selector: 'app-pick-list-wrapper',
  templateUrl: 'pick-list-wrapper.component.html',
  styleUrls: ['pick-list-wrapper.component.css'],
  encapsulation: ViewEncapsulation.None
})
/**
 * @description Automatically generates a pick-list according to data and options given.
 * DEFAULT values have been defined inside PickListOptions (pick-list-options.ts) file.
 * @class PickListWrapperComponent
 * @param {boolean} dragdrop Whether to enable dragdrop based reordering default true.
 * @param {string} field specified the field tho show in pick list
 * @param {string} filterBy When specified displays an input field to filter the items on keyup
 * and decides which field to search (Accepts multiple fields with a comma).
 * @param {string} filterPlaceholderSource Placeholder text on source filter input.
 * @param {string} filterPlaceholderTarget Placeholder text on source filter input.
 * @param {boolean} orderList Param to enable order list template.
 * @param {boolean} responsive When enabled orderlist adjusts its controls based on screen size.
 * @param {boolean} showSourceControls Whether to show buttons of source list.
 * @param {boolean} showTargetControls Whether to show buttons of target list.
 * @param {any[]} sourceData An array of objects for the source list.
 * @param {string} sourceHead Text for the source list caption
 * @param {any[]} targetData An array of objects for the target list.
 * @param {string} targetHead Text for the source list caption
 * @fires {any[]} moveAllToSource Callback to invoke when all items are moved from target to source.
 * @fires {any[]} moveAllToTarget Callback to invoke when all items are moved from source to target.
 * @fires {any} moveToSource Callback to invoke when items are moved from target to source.
 * @fires {any} moveToTarget Callback to invoke when items are moved from source to target.
 */
export class PickListWrapperComponent implements OnInit {
  @Input() public dragdrop?: boolean;
  @Input() public field?: string;
  @Input() public filterBy: string;
  @Input() public filterPlaceholderSource?: string;
  @Input() public filterPlaceholderTarget?: string;
  @Input() public name?: boolean;
  @Input() public nickname?: boolean;
  @Input() public orderList?: boolean;
  @Input() public responsive?: boolean;
  @Input() public showSourceControls?: boolean;
  @Input() public showTargetControls?: boolean;
  @Input() public sourceData: any[];
  @Input() public sourceHead?: string;
  @Input() public targetData: any[];
  @Input() public targetHead?: string;
  @Output() public moveAllToSource: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public moveAllToTarget: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public moveToSource: EventEmitter<any> = new EventEmitter<any>();
  @Output() public moveToTarget: EventEmitter<any> = new EventEmitter<any>();

  public lbl: any;

  constructor(private labelService: LabelService) {
    this.lbl = {
      defaultSourceHead: this.labelService.labelText('viewPickListWrapperSourceHead'),
      defaultTargetHead: this.labelService.labelText('viewPickListWrapperTargetHead'),
      filterSource: this.labelService.labelText('viewPickListWrapperFilterSource'),
      filterTarget: this.labelService.labelText('viewPickListWrapperFilterTarget')
    };
  }

  /**
   * @description Init lyfehook.
   * @return {void}
   */
  public ngOnInit(): void {
    this.initDefaultOptions();
  }

  /**
   * @description Init pick-list wrapper with default options.
   * @return {void}
   */
  private initDefaultOptions(): void {
    this.sourceHead = _.isUndefined(this.sourceHead) ? this.lbl.defaultSourceHead : this.sourceHead;
    this.targetHead = _.isUndefined(this.targetHead) ? this.lbl.defaultTargetHead : this.targetHead;
    this.dragdrop = _.isUndefined(this.dragdrop) ? PickListOptions.DRAGDROP : this.dragdrop;
    this.field = _.isUndefined(this.field) ? PickListOptions.FIELD : this.field;
    this.filterPlaceholderSource =
      _.isUndefined(this.filterPlaceholderSource) ? this.lbl.filterSource : this.filterPlaceholderSource;
    this.filterPlaceholderTarget =
      _.isUndefined(this.filterPlaceholderTarget) ? this.lbl.filterTarget : this.filterPlaceholderTarget;
    this.responsive = _.isUndefined(this.responsive) ? PickListOptions.RESPONSIVE : this.responsive;
    this.orderList = _.isUndefined(this.orderList) ? PickListOptions.ORDER_LIST : this.orderList;
    this.showSourceControls = _.isUndefined(this.showSourceControls) ? PickListOptions.SOURCE_CONTROLS : this.showSourceControls;
    this.showSourceControls = _.isUndefined(this.showSourceControls) ? PickListOptions.SOURCE_CONTROLS : this.showSourceControls;
    this.name = _.isUndefined(this.name) ? PickListOptions.NAME : this.name;
    this.nickname = _.isUndefined(this.nickname) ? PickListOptions.NICKNAME : this.nickname;
  }

  /**
   * @description Callback to invoke when all items are moved from target to source.
   * @param {any[]} items items moved from target to source.
   * @return {void}
   */
  public moveAllItemsToSource(items: any): void {
    this.moveAllToSource.emit(items.items);
  }

  /**
   * @description Callback to invoke when all items are moved from source to target.
   * @param {any[]} items items moved from source to target.
   * @return {void}
   */
  public moveAllItemsToTarget(items: any): void {
    this.moveAllToTarget.emit(items.items);
  }

  /**
   * @description Callback to invoke when items are moved from source to target.
   * @param {any} item item moved from source to target.
   * @return {void}
   */
  public moveItemToTarget(item: any): void {
    this.moveToTarget.emit(item.items);
  }

  /**
   * @description Callback to invoke when items are moved from target to source.
   * @param {any} item item moved from target to source.
   * @return {void}
   */
  public moveItemToSource(item: any): void {
    this.moveToSource.emit(item.items);
  }
}
