import { Directive, ElementRef, HostListener } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: 'input[numbersOnly], [appNumbersOnly]'
})
export class NumberOnlyDirective {

  constructor(private _el: ElementRef) { }


  /**
   * @description Listens for user's keys for validate numbers
   */
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');

    if (!_.isEqual(initalValue, this._el.nativeElement.value)) {
      event.stopPropagation();
    }
  }
}
