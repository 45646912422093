import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { DashboardConfiguration } from './dashboard-configuration';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';

@Injectable({ providedIn: 'root'})
export class DashboardConfigurationService {

  constructor(private http: HttpClient) { }

  /**
   * @description Create dashboard configurations
   * @param {DashboardConfiguration[]} dashboardConfigurations dashboard configurations which will be created
   * @return {Observable<DashboardConfiguration[]>} Created Dashbord configuration
   */
  public createDashboardConfiguration(dashboardConfigurations: DashboardConfiguration[]): Observable<DashboardConfiguration[]> {
    return this.http.post<DashboardConfiguration[]>(ENVIRONMENT.API + '/dashboard-configuration', dashboardConfigurations)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Find the user's dashboards configurations
   * @returns {Observable<DashboardConfiguration[]>} Found dashboard configurations
   */
  public findDashboardConfigurationByUser(): Observable<DashboardConfiguration[]> {
    return this.http.get<DashboardConfiguration[]>(ENVIRONMENT.API + '/dashboard-configuration')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Find the user's dashboards configurations
   * @param {DashboardConfiguration[]} dashboardConfigurations configuration which will be removed
   * @return {Observable<DashboardConfiguration[]>} Found dashboard configurations
   */
  public deleteConfigurations(dashboardConfigurations: DashboardConfiguration[]): Observable<DashboardConfiguration[]> {
    return this.http.put<DashboardConfiguration[]>(ENVIRONMENT.API + '/dashboard-configuration', dashboardConfigurations)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
