import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AutocompleteWrapperComponent } from './autocomplete-wrapper.component';

@NgModule({
  imports: [AutoCompleteModule, CommonModule, FormsModule],
  declarations: [AutocompleteWrapperComponent],
  exports: [AutocompleteWrapperComponent]
})
export class AutocompleteWrapperModule { }
