import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AutocompleteWrapperModule } from '../templates/html/autocomplete-wrapper/autocomplete-wrapper.module';
import { FootprintWrapperComponent } from './footprint-wrapper.component';
import { FootprintWrapperService } from './footprint-wrapper.service';
import { PickingRfService } from '../../process/rf/picking/picking-rf.service';

@NgModule({
  imports: [CommonModule, ButtonModule, DialogModule, FormsModule, CarouselModule, DropdownModule,
    AutocompleteWrapperModule, ReactiveFormsModule],
  declarations: [FootprintWrapperComponent],
  exports: [FootprintWrapperComponent],
  providers: [FootprintWrapperService, PickingRfService]
})
export class FootprintWrapperModule { }
