import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReleaseTask } from '../interfaces/release-task';

@Injectable({ providedIn: 'root' })
export class ReleaseTaskService {
  public releaseSubject: BehaviorSubject<ReleaseTask>;

  constructor() {
    this.releaseSubject = new BehaviorSubject<ReleaseTask>(undefined);
  }

  /**
   * @description Save interface to release task.
   * @param {ReleaseTask} taskToRelease task to will be release.
   * @return {void}
   */
  public saveTaskToRelease(taskToRelease: ReleaseTask): void {
    this.releaseSubject.next(taskToRelease);
  }

  /**
   * @description Retrieves the subject emmitted data as observable.
   * @return {Observable<ReleaseTask>}
   */
  public getReleaseTaskAsObservable(): Observable<ReleaseTask> {
    return this.releaseSubject.asObservable();
  }

  /**
   * @description Release observable to instance properties.
   * @return {void}
   */
  public releaseObservable(): void {
    this.releaseSubject = new BehaviorSubject<ReleaseTask>(undefined);
  }
}
