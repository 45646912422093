import { Component, OnInit } from '@angular/core';
import { LabelService } from '../administration/label-management/label.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public errorMessage: string;
  public names: any[];
  public newName: string;
  public title: string;
  constructor(private labelService: LabelService,
    private location: Location) {
    this.names = [];
    this.newName = '';
    this.title = this.labelService.labelText('viewHomeTitle');
  }

  /**
   * @description OnInit component.
   */
  public ngOnInit(): void {
    window.scrollTo(0, 1);
  }

  /**
   * @description Pushes a new name onto the names array
   * @return {boolean} false to prevent default form submit behavior to refresh the page.
   */
  public addName(): boolean {
    this.names.push(this.newName);
    this.newName = '';
    return false;
  }
}
