export class AreaType {
  areaType: any;
  stock: boolean;
  receivingStage: boolean;
  shipment: boolean;
  shipmentStage: boolean;
  door: boolean;
  ajustment: boolean;
  distributionProcessing: boolean;
  manufacturing: boolean;
  manufacturingStage: boolean;
  crossDock: boolean;
  putToStore: boolean;
  kitting: boolean;
  kittingStage: boolean;
  equipment: boolean;
  packStation: boolean;
  orderPreparationStage: boolean;
  substituteProduct: boolean;
  [key: string]: boolean;
}

export interface AreaTypeColumn {
  field: string;
  label: string;
  value: boolean;
}
