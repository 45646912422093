import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgControl } from '@angular/forms';

import { FormatDate } from '../../format-date';
import { SnapshotUtils } from '../../../utils/utils';

const CHANGE = 'change';
const CLICK = 'click';
const EVENT = '$event';
const PCALENDAR = 'P-CALENDAR';
const PDROPDOWN = 'P-DROPDOWN';
const VALUE = 'value';

@Directive({
  // tslint:disable-next-line:directive-selector Disable because [app] isn't assignable.
  selector: '[snapshot][formControlName],[snapshot][formControl],[snapshot][ngModel]'
})

/**
* @description Takes a snapshot of the current value / state of the ElementRef (input) from current View.
* Current view is automatically obtained from ActivatedRoute's component name, so there's no need to set the view value.
* @class SnapshotDirective
* @param {any} snapshot (Optional) DEFAULT=undefined - snapshot value to Save to localStorage.
* Usually needed if ngModel is not being saved automatically.
* @return {EventEmitter<any>} ngModelChange ngModel value to return to input when there's an ngModel binding
*/
export class SnapshotDirective implements OnInit {
  @Input() snapshot?: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  private id: string;
  private item: any;
  private view: string;

  constructor(private control: NgControl,
  private el: ElementRef,
  private renderer: Renderer2,
  private route: ActivatedRoute) { }

  /**
  * @description Initialize directive after constructor, when view is initializing, since some values are not available in constructor
  * @return {void}
  */
  public ngOnInit(): void {
    this.id = this.el.nativeElement.id;
    this.view = this.route.routeConfig.component.name;
    this.item = SnapshotUtils.getSavedElement(this.view, this.id);
    if (this.item) {
      this.renderer.setProperty(this.el.nativeElement, VALUE, this.item.value);
      if (this.control.control.parent) {
        this.control.control.setValue(this.item.value);
      }
      this.ngModelChange.emit(this.item.value);
    }
  }

  /**
  * @description Listen for ElementRef's change event and trigger save state to localStorage
  */
  @HostListener(CHANGE, [EVENT]) onchange(e: any) {
    if (this.snapshot) {
      SnapshotUtils.saveState(this.view, this.id, e.target.value);
    }
  }

  /**
  * @description Listen for ElementRef's click event. Only needs to save if the node is a p-calendar to save state,
  * since p-calendar component has no onChange event when selecting dates from overlayed calendar
  */
  @HostListener(CLICK, [EVENT]) onclick(e: any) {
    if (this.el.nativeElement.nodeName === PCALENDAR && this.snapshot) {
      SnapshotUtils.saveState(this.view, this.id, FormatDate.getFormattedDate(this.snapshot));
    } else if (this.el.nativeElement.nodeName === PDROPDOWN && this.snapshot) {
      SnapshotUtils.saveState(this.view, this.id, this.snapshot);
    }
  }
}
