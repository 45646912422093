export class KeyCode {
  public static readonly Backspace = 'Backspace';
  public static readonly Tab = 'Tab';
  public static readonly Enter = 'Enter';
  public static readonly Escape = 'Escape';
  public static readonly Delete = 'Delete';
  public static readonly F1 = 'F1';
  public static readonly F2 = 'F2';
  public static readonly F3 = 'F3';
  public static readonly F4 = 'F4';
  public static readonly F5 = 'F5';
  public static readonly F6 = 'F6';
  public static readonly F7 = 'F7';
  public static readonly F8 = 'F8';
  public static readonly F9 = 'F9';
  public static readonly F10 = 'F10';
  public static readonly F11 = 'F11';
  public static readonly F12 = 'F12';
  public static readonly Minus = 'Slash';
  public static readonly NumericMinus = '-';
}
