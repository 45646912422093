import { Interfaceble } from '../shared/interfaces/interfaceble';

export class Lpn implements Interfaceble {
  private _id: number;
  private _lpn: string;
  private _is_active: number;
  private _is_available: number;
  private _is_reusable: number;
  private _last_change: string;
  private _user_id: number;
  private _isAudit: number;

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  public get is_reusable(): number {
    return this._is_reusable;
  }

  public set is_reusable(value: number) {
    this._is_reusable = value;
  }

  get lpn(): string {
    return this._lpn;
  }

  set lpn(lpn: string) {
    this._lpn = lpn;
  }

  get is_active(): number {
    return this._is_active;
  }

  set is_active(is_active: number) {
    this._is_active = is_active;
  }

  get is_available(): number {
    return this._is_available;
  }

  set is_available(is_available: number) {
    this._is_available = is_available;
  }

  get last_change(): string {
    return this._last_change;
  }

  set last_change(last_change: string) {
    this._last_change = last_change;
  }

  get user_id(): number {
    return this._user_id;
  }

  set user_id(user_id: number) {
    this._user_id = user_id;
  }

  get isAudit(): number {
    return this._isAudit;
  }

  set isAudit(isAudit: number) {
    this._isAudit = isAudit;
  }

  public toJSON(): Object {
    return {
      id: this.id,
      lpn: this.lpn,
      is_active: this.is_active,
      is_available: this.is_available,
      last_change: this.last_change,
      user_id: this.user_id,
    };
  }

  public toInterface() {
    return {
      lpn: this.lpn
    };
  }
}
