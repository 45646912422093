import { UOM } from '../uom/uom';

export class FootprintDetail {
  id: number;
  large: number;
  width: number;
  height: number;
  weight: number;
  isPickable: boolean;
  isLevelSerial: boolean;
  isBaseUnit: boolean;
  conversionFactor: number;
  isPalletLevel: boolean;
  isCaseLevel: boolean;
  isPackageLevel: boolean;
  isEachLevel: boolean;
  uom: UOM;
  isActive: boolean;
  lastChange: Date;
  userId: number;
  footprintId: number;
  uomLevel: string;
  isDefault: boolean;

  [uomLevel: string]: any;

  constructor() {
    this.id = undefined;
    this.large = undefined;
    this.width = undefined;
    this.height = undefined;
    this.weight = undefined;
    this.isPickable = false;
    this.isLevelSerial = false;
    this.isBaseUnit = false;
    this.conversionFactor = undefined;
    this.isPalletLevel = false;
    this.isCaseLevel = false;
    this.isPackageLevel = false;
    this.isEachLevel = false;
    this.uom = new UOM();
    this.isActive = false;
    this.lastChange = new Date();
    this.userId = undefined;
    this.footprintId = undefined;
    this.uomLevel = '';
    this.isDefault = false;
  }
}
