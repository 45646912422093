import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { ENVIRONMENT } from '../../environments/environment';
import { HandleError } from '../utils/utils';
import { PickingTaskReport } from './picking-task-dashboard-wrapper/picking-task-report';
import { ReceiptAppointmentReport } from './receipt-appointment-dashboard-wrapper/receipt-appointment-report';
import { TaskReport } from './pending-task-dashboard-wrapper/task-report';

@Injectable({ providedIn: 'root' })
export class DashboardService {

  constructor(private http: HttpClient) { }

  /**
   * @Description Get the tasks report filtered by date and task type
   * @param {(string[] | string)} taskTypes Task type to filter results
   * @param {any} dateFilter Filters date to apply.
   * @return {Observable<TaskReport[]>} Tasks report filtered
   */
  public getPendingTaskReportByDate(taskTypes: string[] | string, dateFilter: any): Observable<TaskReport[]> {
    let criteria: Object[] = [];
    let between = dateFilter.between;

    if (!_.isEqual(typeof taskTypes, 'string')) {
      criteria.push({ field: 'task.type', condition: 'in', value: taskTypes });
    } else {
      criteria.push({ field: 'task.type', condition: '=', value: taskTypes });
    }
    const route = `/dashboard/pending-tasks?criteria=${JSON.stringify(criteria)}&between=${JSON.stringify(between)}`;

    return this.http.get<TaskReport[]>(ENVIRONMENT.API + route)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @Description Get the receipt appointment report filtered by date
   * @param {any} dateFilter Date filters to apply
   * @return {Observable<TaskReport[]>} Tasks report filtered
   */
  public getReceiptAppointmentReport(dateFilter: any): Observable<ReceiptAppointmentReport[]> {
    let between = dateFilter.between;
    return this.http.get<ReceiptAppointmentReport[]>(ENVIRONMENT.API + '/dashboard/receipt-appointment?between=' + JSON.stringify(between))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get the task report with individual productivity reports.
   * @param {string | string[]} taskType Task type to filter
   * @param {any} dateFilter Filters date to apply
   * @return {Observable<TaskReport>} Task report with individual productivity reports
   */
  public getIndiviualProductivityReport(taskType: string | string[], dateFilter: any): Observable<TaskReport> {
    let criteria: Object[] = [];
    let between = dateFilter.between;

    if (_.isEqual(typeof taskType, 'string')) {
      criteria.push({ field: 'mh.movement_type', condition: '=', value: taskType });
    } else {
      criteria.push({ field: 'mh.movement_type', condition: 'in', value: taskType });
    }
    return this.http.get<TaskReport>(ENVIRONMENT.API + '/dashboard/individual-productivity?between='
    + JSON.stringify(between) + '&criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get picking task reports
   * @param {any} dateFilter Filters date to apply
   * @return {Observable<PickingTaskReport[]>} Picking task reports found
   */
  public getPendingPickingTaskReport(dateFilter: any): Observable<PickingTaskReport[]> {
    let between = dateFilter.between;
    return this.http.get<PickingTaskReport[]>(ENVIRONMENT.API + '/dashboard/pending-picking-tasks?between=' + JSON.stringify(between))
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}

