import { Injectable } from '@angular/core';

import { LabelService } from '../../administration/label-management/label.service';
import { RouterScreen } from './routerScreen.interface';
import { SnapshotUtils } from '../../utils/utils';
import TAGSDROPDOWN from './screens.mapping';

const SCREENS = 'screens';

@Injectable()
export class HistoryService {

  constructor(private labelService: LabelService) {  }

  /**
  * @description Class get ModuleWithProviders
  * @return {RouterScreen[]}
  */
  public getScreens(): RouterScreen[] {
    let routerScreens: RouterScreen[] = [];
    let currentState: any[];
    currentState = SnapshotUtils.getState(SCREENS);
    currentState.forEach(state => {
      routerScreens.push(state.value);
    });
    return routerScreens;
  }

  /**
  * @description Function to erase screen of the Array in localStorage
  * @param {string} key name of the component that will be find in the array
  * @return {void}
  */
  public deleteScreen(key: string): void {
    SnapshotUtils.removeSavedElement(SCREENS, key);
  }

  /**
  * @description Function to get the labels in Spanish
  * @param {string} componentName name of the view that obtains with the router
  * @return {string}
  */
  public getTranslateName(componentName: string): string {
    let translate: string = this.labelService.labelText((<any>TAGSDROPDOWN)[componentName]);
    return translate;
  }

}
