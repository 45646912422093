export const TAGSDROPDOWN = {
  AboutComponent: 'viewAbout',
  AccessoryAddComponent: 'createAccessory',
  AccessoryComponent : 'viewAccessoryTitle',
  AccessoryEditComponent: 'updateAccessory',
  AccountAddComponent: 'viewAddAccountTitle',
  AccountComponent: 'viewAccountTitle',
  AccountEditComponent: 'viewEditAccountTitle',
  AddressAddComponent : 'viewAddressAddTitle',
  AddressComponent : 'viewAddressTitle',
  AddressEditComponent : 'viewAddressEditTitle',
  AreaAddComponent : 'createArea',
  AreaComponent : 'viewAreaTitle',
  AreaEditComponent : 'updateArea',
  AttributeChangeComponent : 'attributeChangeTitle',
  BuildingAddComponent : 'createBuilding',
  BuildingComponent : 'viewBuildingTitle',
  BuildingEditComponent: 'createBuilding',
  CarrierAddComponent : 'addCarrierTitle',
  CarrierComponent : 'carrierTitle',
  CarrierEditComponent : 'editCarrierTitle',
  CheckInventoryLocationComponent: 'checkInventoryLocation',
  CheckInventoryLpnComponent: 'checkInventoryLpn',
  CustomerAddComponent : 'createCustomer',
  CustomerComponent : 'viewCustomerTitle',
  CustomerEditComponent : 'updateCustomer',
  CycleCountLocationComponent : 'countLocationTitle',
  EquipmentAddComponent : 'createEquipment',
  EquipmentComponent : 'viewEquipmentTitle',
  EquipmentEditComponent : 'updateEquipment',
  EquipmentTypeAddComponent: 'createEquipmentType',
  EquipmentTypeComponent : 'viewEquipmentTypeTitle',
  EquipmentTypeEditComponent : 'updateEquipmentType',
  FamilyAddComponent : 'createFamily',
  FamilyComponent : 'viewFamilyTitle',
  FamilyEditComponent : 'updateFamily',
  FootprintAddComponent : 'createFootprint',
  FootprintComponent : 'viewFootprintTitle',
  FootprintEditComponent : 'updateFootprint',
  GenerateCountComponent : 'generateCountTitle',
  HomeComponent: 'viewHome',
  InputFieldConfigurationComponent: 'inputFieldConfigurationTitle',
  InventoryAnalysisComponent : 'inventoryAnalysisTitle',
  InventoryReportComponent : 'viewInventoryReportTitle',
  InventoryStatusAddComponent : 'createInventoryStatus',
  InventoryStatusComponent : 'inventoryStatusTitle',
  InventoryStatusEditComponent : 'updateInventoryStatus',
  LabelComponent : 'viewLabelsTitle',
  LocationAddComponent : 'createLocation',
  LocationComponent : 'viewLocationTitle',
  LocationEditComponent : 'updateLocation',
  LogComponent : 'viewLoginTitle',
  ManualCycleCountComponent : 'manualCycleCountTitle',
  MovementHistoryComponent : 'viewMovementHistoryTitle',
  PhysicalCountComponent : 'viewPhysicalCountTitle',
  PickingComponent : 'viewPickingTitle',
  PickingLpnComponent : 'viewPickingLpnTitle',
  PickingRfComponent : 'viewPickingRFTitle',
  PolicyParamComponent: 'paramsTitle',
  PrinterLabelAddFileComponent : 'viewPrinterFileAdd',
  PrinterLabelComponent : 'viewPrinter',
  ProductAddComponent : 'createProduct',
  ProductComponent : 'viewProductTitle',
  ProductEditComponent : 'updateProduct',
  ProgrammedCycleCountComponent : 'programmedCycleCountTitle',
  ProgressionAddComponent : 'createProgression',
  ProgressionComponent : 'progressionTitle',
  ProgressionEditComponent : 'updateProgression',
  PutAwayPolicyAddComponent : 'putAwayPolicyAddTitle',
  PutAwayPolicyComponent : 'putAwayPolicyTitle',
  PutAwayPolicyEditComponent : 'putAwayPolicyEditTitle',
  ReallocateLPNComponent : 'viewReallocateLpnTitle',
  ReallocateProductComponent : 'viewReallocateProductTitle',
  ReasonAddComponent : 'addReason',
  ReasonComponent : 'titleReasonCatalog',
  ReasonEditComponent : 'viewReasonEditTitle',
  ReceiptComponent : 'viewReceiptTitle',
  ReceiptRfComponent : 'viewReceiptRFTitle',
  RfMonitorComponent : 'viewRFMonitorTitle',
  RoleAddComponent : 'createRole',
  RoleComponent : 'viewRoleTitle',
  RoleEditComponent : 'updateRole',
  SubfamilyAddComponent : 'createSubfamily',
  SubfamilyComponent : 'viewSubfamilyTitle',
  SubfamilyEditComponent : 'updateSubfamily',
  TaskComponent : 'viewTaskTitle',
  UOMAddComponent : 'createUOM',
  UOMComponent: 'viewUOMTitle',
  UOMEditComponent: 'updateUOM',
  UserAddComponent : 'createUser',
  UserComponent : 'viewUserTitle',
  UserEditionComponent : 'createUser',
  WarehouseAddComponent : 'createWarehouse',
  WarehouseComponent : 'viewWarehouseTitle',
  WarehouseEditComponent : 'updateWarehouse',
  WorkAreaAddComponent : 'createWorkArea',
  WorkAreaComponent : 'viewWorkAreaTitle',
  WorkAreaEditComponent : 'updateWorkArea',
  WorkZoneAddComponen: 'createWorkZone',
  WorkZoneComponent: 'viewWorkZoneTitle',
  WorkZoneEditComponen: 'updateWorkZone'
};
export default TAGSDROPDOWN;
