export class SpecialKeyCode {
    public static readonly ADMIRATION_SIGN: string = '!';
    public static readonly AMPERSON: string = '&';
    public static readonly AT_SIGN: string = '@';
    public static readonly BACK_SLASH: string = 'Backslash';
    public static readonly BACKQUOTE: string = 'Backquote';
    public static readonly BRACKET_LEFT: string = 'BracketLeft';
    public static readonly BRACKET_RIGHT: string = 'BracketRight';
    public static readonly CLOSE_PARENTHESIS: string = ')';
    public static readonly COMMA: string = 'Comma';
    public static readonly DIAGONAL: string = '/';
    public static readonly EQUAL_SIGN: string = '=';
    public static readonly EQUAL: string = 'Equal';
    public static readonly HASH_TAG: string = '#';
    public static readonly INT_BACK_SLASH: string = 'IntlBackslash';
    public static readonly MINUS: string = 'Minus';
    public static readonly MONEY: string = '$';
    public static readonly OPEN_PARENTHESIS: string = '(';
    public static readonly PERCENTAGE: string = '%';
    public static readonly PERIOD: string = 'Period';
    public static readonly QUOTATION_MARKS: string = '"';
    public static readonly QUOTE: string = 'Quote';
    public static readonly SLASH_DOWN: string = '_';
}
