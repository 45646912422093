import { AreaType } from './area-type/area-type';
import { Building } from '../building/building';
import { CapacityCode } from '../capacity-code/capacity-code';

export class Area {
  id: number;
  code: string;
  description: string;
  sequence: string;
  isPickingPart: boolean;
  isPickingCase: boolean;
  isPickingPallet: boolean;
  isDynamicSlotting: boolean;
  areaType: AreaType;
  capacityCode: CapacityCode;
  isActive: boolean;
  lastChange: Date;
  userId: number;
  building: Building;
  isPackStationStage: boolean;
  sentInterface: boolean;

  constructor() {
    this.id = undefined;
    this.code = '';
    this.description = '';
    this.sequence = '';
    this.isPickingPart = false;
    this.isPickingCase = false;
    this.isPickingPallet = false;
    this.isDynamicSlotting = false;
    this.areaType = new AreaType();
    this.isActive = false;
    this.userId = undefined;
    this.isPackStationStage = false;
    this.sentInterface = false;
  }
}
