export class EquipmentType {
  id: number;
  name: string;
  description: string;
  numberOfPallets: number;
  minimumTemperature: number;
  lastChange: Date;
  userId: number;
  locationAccess: string[];

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
    this.numberOfPallets = undefined;
    this.minimumTemperature = undefined;
    this.lastChange = new Date();
    this.userId = undefined;
    this.locationAccess = [];
  }
}
