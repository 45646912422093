export class WepError {
  internalCode?: number;
  message: string;
  error: any;
  data: any;

  constructor() {
    this.internalCode = null;
    this.message = null;
    this.error = null;
    this.data = null;
  }
}
