import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickListModule } from 'primeng/picklist';

import { PickListWrapperComponent } from './pick-list-wrapper.component';

@NgModule({
  imports: [CommonModule, PickListModule],
  declarations: [PickListWrapperComponent],
  exports: [PickListWrapperComponent]
})
export class PickListWrapperModule { }
