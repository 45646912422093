import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScfToastrModule } from 'scf-library';

import { EqualValidatorDirective } from '../administration/password/password-validator/equal-validator.directive';
import { EquipmentService } from '../catalog/equipment/equipment.service';
import { HistoryService } from '../shared/history/history.service';
import { LicenseService } from '../administration/license/license.service';
import { LoginComponent } from './login.component';
import { PasswordRecoveryService } from '../administration/password/password-recovery/password-recovery.service';
import { PendingWorkService } from '../process/pending-work/pending-work.service';
import { RoleService } from '../administration/role/role.service';
import { UserService } from '../administration/user/user.service';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UtilsModule, ScfToastrModule],
  declarations: [EqualValidatorDirective, LoginComponent],
  exports: [FormsModule, LoginComponent, ReactiveFormsModule],
  providers: [EquipmentService, HistoryService, LicenseService, PasswordRecoveryService,
    PendingWorkService, RoleService, UserService]
})
export class LoginModule { }
