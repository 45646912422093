export class InventoryStatus {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  destinationStatusId: number;
  destinationStatusName: string;
  lifeDays: number;

  constructor() {
    this.name = '';
    this.description = '';
  }
}
