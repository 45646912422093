import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldWrapperComponent } from './field-wrapper.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [FieldWrapperComponent],
  exports: [FieldWrapperComponent],
})
export class FieldWrapperModule { }
