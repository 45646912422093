import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT } from '../../../environments/environment';
import { FootprintDetail } from '../../catalog/footprint/footprint-detail';
import { FootprintDetailRf } from '../../catalog/footprint/footprint-detail-rf';
import { HandleError } from '../../utils/utils';
import { Product } from '../../catalog/product/product';

@Injectable()
export class FootprintWrapperService {
  constructor(private http: HttpClient) { }

  /**
   * @description Get the list of footprints related to sku.
   * @param {string} sku Product related to footprints.
   * @param {number} accountId Account id used to filter data.
   * @returns {Observable<Product>}
   */
  public getFootprints(sku: string, accountId: number): Observable<Product> {
    return this.http.get<Product>(ENVIRONMENT.API + '/products/' + encodeURIComponent(sku) + '/footprints' + '?accountId=' + accountId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Obtains the footprint details by SKU.
   * @param {string} sku - Product to look for.
   * @param {number} accountId - Account id to look for.
   * @returns {Observable<FootprintDetail[]>}
   */
  public getFootprintDetailsBySKU(sku: string, accountId: number): Observable<FootprintDetailRf[]> {
    let skuFilter: Object[] = [];
    skuFilter.push({
      field: 'p.sku',
      value: encodeURIComponent(sku),
      condition: '='
    });
    skuFilter.push({
      field: 'p.account_id',
      value: accountId,
      condition: '='
    });
    return this.http.get<FootprintDetailRf[]>(ENVIRONMENT.API + '/footprints/details-rf-sku?criteria=' + JSON.stringify(skuFilter))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Obtains the footprint details by id of the selected footprint.
   * @param {number} id - The footprint id to look up for.
   * @return {Observable<FootprintDetail[]>} - List of FootprintDetail objects.
   */
  public getFootprintDetails(id: number): Observable<FootprintDetailRf[]> {
    return this.http.get<FootprintDetailRf[]>(ENVIRONMENT.API + '/footprints/details-rf/' + id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets an footprint by its id
   * @param {number} footprintId Footprint Id
   * @return {Observable<FootprintDetail[]>} FootprintDetail information
   */
  public getDetailById(footprintId: number): Observable<FootprintDetail[]> {
    return this.http.get<FootprintDetail[]>(ENVIRONMENT.API + '/footprints/' + footprintId +
      '/footprint-details').pipe(catchError(HandleError.handleErrorObservable));
  }
}
