export enum TaskTypeEnum {
  PutAway = 1,
  Restocking = 2,
  CyclicCount = 3,
  PartialProductTransfer = 4,
  PickingList = 5,
  PickingPallet = 6,
  DetailPutAway = 7,
  LevelReplenishment = 8,
  TopOffReplenishment = 9,
 }

export enum TaskStatusEnum {
  Available = 1,
  Done = 2,
  Assigned = 3,
  Cancelled = 4,
  Hold = 5,
  InProgress = 6
}

export class TaskType {
  static readonly BulkPicking = 'BulkPicking';
  static readonly CyclicCount = 'CyclicCount';
  static readonly DetailPutAway = 'DetailPutAway';
  static readonly EmergingReplenishment = 'EmergingReplenishment';
  static readonly LevelReplenishment = 'LevelReplenishment';
  static readonly PickingList = 'PickingList';
  static readonly PickingListWorkingOrder = 'PickingListWorkingOrder';
  static readonly PickingPallet = 'PickingPallet';
  static readonly PickingPalletWorkingOrder = 'PickingPalletWorkingOrder';
  static readonly PutAway = 'PutAway';
  static readonly Replenishment = 'Replenishment';
  static readonly Restocking = 'Restocking';
  static readonly TopOffReplenishment = 'TopOffReplenishment';
  static readonly PreparationStage = 'PreparationStage';
 }

export class TaskTypeClass {
  value: number;
  description: string;
}
