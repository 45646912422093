export class Session {
  id: number;
  ip: string;
  startDate: Date;
  userId?: number;
  user: string;
  account: string;
  accountId?: number;
  warehouse: string;
  warehouseId?: number;
  equipment?: string;
  equipmentId?: number;

  constructor() {
    this.id = undefined;
    this.ip = '';
    this.startDate = new Date();
    this.userId = undefined;
    this.user = '';
    this.account = '';
    this.accountId = undefined;
    this.warehouse = '';
    this.warehouseId = undefined;
    this.equipment = '';
    this.equipmentId = undefined;
  }
}
