import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Label } from '../../administration/label-management/label';
import { LabelService } from '../../administration/label-management/label.service';

const LABELS = 'labels';

@Injectable()
export class SystemLabelsResolver implements Resolve<Promise<Array<Label>>> {

  constructor(private labelService: LabelService) { }

  /**
   * @description Route Guard to set system labels
   * @returns {Promise<Array<Label>>}
   */
  public resolve(): Promise<Label[]> {
    return this.setSystemLabels();
  }

  /**
   * @description Set system labels
   * @returns {Promise<Array<Label>>}
   */
  public setSystemLabels(): Promise<Label[]> {
    let resolverPromise: Promise<Label[]>;
    resolverPromise = new Promise((resolve, reject) => {
      this.labelService.getSystemLabels().subscribe(
        (response: Label[]) => {
          this.labelService.labels = response;
          localStorage.setItem(LABELS, JSON.stringify(response));
          return resolve(response);
        },
        error => {
          // TODO implements Error Handling,
          // this console-log is only to know if there was an error getting the labels
          console.log('Error retriving system\'s labels');
          reject(null);
        }
      );
    });
    return resolverPromise;
  }
}
