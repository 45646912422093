import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api/selectitem';
import * as _ from 'lodash';

import { ENVIRONMENT } from '../../../environments/environment';
import { HistoryService } from './history.service';
import { LabelService } from '../../administration/label-management/label.service';
import { RouterScreen } from './routerScreen.interface';
import { SnapshotUtils } from '../../utils/utils';

const LABEL = 'Label for';
const LOGIN = 'LoginComponent';
const PRIMARY = 'primary';
const SCREENS = 'screens';

@Component({
  selector: 'app-history',
  templateUrl: 'history.component.html',
  styleUrls: ['history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HistoryComponent implements OnInit {
  public selectedScreen: SelectItem[];
  public screens: RouterScreen[];
  public screen: string;
  public title = '';

  public lbl = {
    placeholderHistory : this.labelService.labelText('placeholderHistoryView'),
    deleteScreenBtn: this.labelService.labelText('closeButton')
  };

  constructor(private labelService: LabelService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private historyService: HistoryService) {
        this.screens = [];
   }

   /**
   *@description Init Component
   *@return {void}
   */
   public ngOnInit(): void {
     this.subscribeRouter();
   }

   /**
   * @description Function that subscribe to the router events
   * @return {void}
   */
   public subscribeRouter(): void {
     this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter((route) => _.isEqual(route.outlet, PRIMARY)))
      .subscribe(activatedRoute => {
        let url: string = this.router.url;
        let component: string = (!_.isNull(activatedRoute.routeConfig)) ? activatedRoute.routeConfig.component.name : undefined;
        let translateComponent: string = this.historyService.getTranslateName(component);
        let routerScreen = { title: translateComponent, component: component, routerLink: url };
        if (component !== undefined && component !== LOGIN && translateComponent !== LABEL) {
          SnapshotUtils.saveState(SCREENS, translateComponent, routerScreen);
        }
      });
  }

  /**
  * @description Display screens list
  * @return {void}
  */
  public displayScreens(): void {
    setTimeout(() => {
      this.screens = this.historyService.getScreens();
    }, _.toInteger(ENVIRONMENT.MSGTIME));
  }

  /**
  * @description Function with the router to navigate to a view in system
  * @param {RouterScreen} screen name of the url of the screen to navigate
  * @return {void}
  */
  public navigateLink(screen: RouterScreen): void {
    this.screen = '';
    this.router.navigate([screen.routerLink]);
  }

  /**
  * @description Function to delete a value of the history
  * @param {RouterScreen} screen Screen's object
  * @return {void}
  */
  public deleteScreen(screen: RouterScreen): void {
    this.screen = '';
    SnapshotUtils.clearState(screen.component);
    this.historyService.deleteScreen(screen.title);
   }
}
